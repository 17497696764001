<template>
  <div class="status-charts">
    <CommonLoading v-if="loading" height="100%" img-size="30" />
    <div v-else>
      <div id="J_Echart"></div>
    </div>
  </div>
</template>

<script setup>
import * as echarts from 'echarts/core'
import { TooltipComponent, GridComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import dayjs from 'dayjs'

import { getTrendGetails } from '~/api/status'

const props = defineProps({
  id: {
    type: String,
    default: () => {}
  }
})

const xlist = ref([])
const ylist = ref([])
const loading = ref(false)

onMounted(() => {
  ylist.value = []

  loading.value = true
  getTrendGetails({
    key: props.id
  })
    .then(res => {
      loading.value = false

      res.data.forEach(item => {
        xlist.value.push(dayjs(item.time * 1).format('YYYY-MM-DD HH:mm:ss'))
        ylist.value.push(item.value)
      })

      setTimeout(() => {
        echartInit()
      })
    })
    .catch(() => {
      loading.value = false
    })
})

const echartInit = () => {
  echarts.use([TooltipComponent, GridComponent, LineChart, UniversalTransition, CanvasRenderer])

  const chartDom = document.getElementById('J_Echart')
  const myChart = echarts.init(chartDom)
  const option = {
    grid: {
      top: 3,
      right: 0,
      bottom: 0,
      left: 0
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#0252D9',
      borderWidth: 0,
      padding: [3, 10],
      textStyle: {
        color: '#FFF',
        fontSize: 14
      },
      formatter: '{b0}，{c0}'
    },
    xAxis: {
      show: false,
      type: 'category',
      boundaryGap: false,
      data: xlist.value
    },
    yAxis: {
      show: false,
      type: 'value',
      min: 'dataMin',
      max: 'dataMax'
    },
    series: [
      {
        type: 'line',
        smooth: true,
        showSymbol: false,
        symbolSize: 7,
        lineStyle: {
          width: 0.5,
          color: '#1D4FD8'
        },
        areaStyle: {
          opacity: 0.2,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(29, 79, 216)'
            },
            {
              offset: 1,
              color: 'rgba(245, 248, 253)'
            }
          ])
        },
        emphasis: {
          focus: 'series'
        },
        data: ylist.value
      }
    ]
  }

  option && myChart.setOption(option)
}
</script>

<style lang="scss" scoped>
.status-charts {
  height: 100%;

  #J_Echart {
    width: 1360px;
    height: 80px;
  }
}
</style>
