export function mqStatus(params) {
  return request(`/user/monitoring-dt/mq`, {
    method: 'GET',
    params
  })
}

// 列表数据
export function getMqStatusV2(params) {
  return request(`/user/monitoring-dt/v2/mq`, {
    method: 'GET',
    params
  })
}

// 单个echarts详情
export function getTrendGetails(params) {
  return request(`/user/monitoring-dt/v1/trend-details`, {
    method: 'POST',
    params
  })
}
